import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t, strings } from 'language';
import useGlobal from 'store/global';
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import { SelectImage } from 'store/ducks/annotate';
import { getIdFromUrl } from 'utils/helpers';
import { InferenceAPI } from 'services/Api/Inference';
import { DatasetAPI } from 'services/Api/Dataset';
import { resolveParameters } from 'services/Api';
import { setPage } from 'store/ducks/parameters';
import { Col, CarouselDiv, ShowLink, Input, InputContainer } from './styles';
import TooltipItem from './components/TooltipItem';
import ButtonIcon from './ButtonIcon';
import { ReactComponent as PrevIcon } from 'assets/images/left.svg';
import { ReactComponent as NextIcon } from 'assets/images/right.svg';
import useKeyPress from './components/useKeyPress';
import { Icon } from 'components/Icon';

function Carousel(props) {
  const { history, size, theme } = props;
  const location = history.location.pathname;
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const isInference = !!(inferenceID > 0 && getIdFromUrl(location, 3) === 'inference');
  const annotate = useSelector(state => (isInference ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  const totalImges = annotate.totalImages;
  const dataset = getIdFromUrl(location, 2);
  const dispatch = useDispatch();
  const [actionTagWidth] = useGlobal(state => state.actionTagWidth);
  const width = size && size.width - actionTagWidth;
  const gridHeight = size && parseInt((size.height - 70) / 100, 10) * 100 - 50;
  const [, setColumn] = useState(5);
  const [index, setIndex] = useGlobal(
    state => state.index,
    actions => actions.setIndex
  );
  const [textLink, setTextLink] = useState(t(strings.show_all_images));
  const [height, setHeight] = useState(104);
  const [overflow, setOverflow] = useState('hidden');
  const [, setHeightImages] = useGlobal(
    state => state.heightImages,
    actions => actions.setHeightImages
  );
  const [, setWidthImages] = useGlobal(
    state => state.widthImages,
    actions => actions.setWidthImages
  );
  const [editMode] = useGlobal(state => state.editMode);
  const ArrowLeft = useKeyPress('ArrowLeft');
  const ArrowRight = useKeyPress('ArrowRight');
  const KeyALeft = useKeyPress('a');
  const KeyDRight = useKeyPress('d');
  const [data, setData] = useState([]);
  const refs = data.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});
  const [carousel, setCarousel] = useState({});
  const [newIndex, setNewIndex] = useState(-1);
  const [image, setImage] = useState('');
  const paramState = useSelector(state => state.parameters);
  const [relaodAnnotate] = useGlobal(state => state.relaodAnnotate);
  const selectedColor = theme.white;

  const [disableEditor] = useGlobal(state => state.disableEditor);
  const [disableSelector] = useGlobal(state => state.disableSelector);
  const [disableOverlay] = useGlobal(state => state.disableOverlay);
  const [disableContent] = useGlobal(state => state.disableContent);

  useEffect(() => {
    setData([]);
    if (isInference) {
      getDataInference();
    } else {
      getData();
    }
  }, [paramState, relaodAnnotate]);

  useEffect(() => {
    if (newIndex > -1 && paramState.page === carousel.currentPage) {
      const img = data.find((obj, idx) => {
        return idx === parseInt(newIndex, 10);
      });
      showImg(img);
      setNewIndex(-1);
    }
  }, [newIndex, data]);

  useEffect(() => {
    const handleClick = id => {
      if (data.length > 0) {
        refs[id] &&
          refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
      }
    };
    handleClick(selectedImage.id);
    if (newIndex > -1 && paramState.page === carousel.currentPage) {
      const img = data.find((obj, idx) => {
        return idx === parseInt(newIndex, 10);
      });
      showImg(img);
      setNewIndex(-1);
    }
  }, [data, selectedImage.id]);

  useEffect(() => {
    setColumn(width && parseInt((width - 180) / 100, 10));
    setWidthImages(width);
  }, [width, size, actionTagWidth, totalImges]);

  useEffect(() => {
    if (ArrowLeft) {
      showImage(index - 1);
    }
    if (ArrowRight) {
      showImage(index + 1);
    }
    if (!disableEditor && !disableSelector && !disableOverlay && !disableContent) {
      if (KeyALeft) {
        showImage(index - 1);
      }
      if (KeyDRight) {
        showImage(index + 1);
      }
    }
  }, [ArrowLeft, ArrowRight, KeyALeft, KeyDRight, totalImges]);

  function reloadCarousel() {
    setData([]);
    if (isInference) {
      getDataInference();
    } else {
      getData();
    }
  }

  function handleShow() {
    if (textLink === t(strings.show_all_images)) {
      const newHeight = parseInt((gridHeight + 20) / 100, 10) * 100 + 80;
      setTextLink(t(strings.collapse_images));
      setHeight(newHeight);
      setHeightImages(newHeight + 23);
      setOverflow('auto');
    } else {
      handleClose();
    }
  }

  function handleClose() {
    setTextLink(t(strings.show_all_images));
    setHeight(104);
    setHeightImages(136);
    setOverflow('hidden');
  }

  async function showImg(img) {
    const params = `${resolveParameters({
      page: paramState?.page,
      query: paramState?.search,
      markers: paramState?.markers,
      orderBy: paramState?.orderby,
      tags: paramState?.tags,
      withoutTags: paramState?.withoutTags
    })}`;
    if (!editMode) {
      if (isInference) {
        history.push(`/dataset/${dataset}/inference/${inferenceID}/annotate/${img.id}${params}`);
      } else {
        history.push(`/dataset/${dataset}/annotate/${img.id}${params}`);
      }
      handleClose();
      dispatch(SelectImage(img));
      reloadCarousel();
    } else {
      toast.error(t(strings.error_editing));
    }
  }

  async function showImage(newIdx) {
    const max =
      carousel.currentPage < carousel.pageCount
        ? carousel.pageSize
        : carousel.rowCount - carousel.pageCount * carousel.pageSize * -1;
    if (newIdx > -1 && newIdx < max) {
      const img = data.find((obj, idx) => {
        return idx === parseInt(newIdx, 10);
      });
      if (!editMode) {
        const params = `${resolveParameters({
          page: paramState?.page,
          query: paramState?.search,
          markers: paramState?.markers,
          orderBy: paramState?.orderby,
          tags: paramState?.tags,
          withoutTags: paramState?.withoutTags
        })}`;
        if (img) {
          if (isInference) {
            history.push(
              `/dataset/${dataset}/inference/${inferenceID}/annotate/${img.id}${params}`
            );
          } else {
            history.push(`/dataset/${dataset}/annotate/${img.id}${params}`);
          }
          dispatch(SelectImage(img));
        }
      } else {
        toast.error(t(strings.error_editing));
      }
    } else {
      if (carousel.currentPage > 1 && newIdx === -1) {
        prevPage();
      }
      if (carousel.currentPage < carousel.pageCount && newIdx === carousel.pageSize) {
        nextPage();
      }
    }
    handleClose();
  }

  function nextPage() {
    const page = carousel.currentPage + 1;
    dispatch(setPage(page));
    setNewIndex(0);
    if (isInference) {
      getDataInference();
    } else {
      getData();
    }
  }

  function prevPage() {
    const page = carousel.currentPage - 1;
    dispatch(setPage(page));
    setNewIndex(carousel.pageSize - 1);
    if (isInference) {
      getDataInference();
    } else {
      getData();
    }
  }

  function onChange(value) {
    if (value < 1) {
      return setImage(1);
    }
    if (value > carousel.rowCount) {
      return setImage(carousel.rowCount);
    }
    setImage(value);
  }

  function onSubmit(value) {
    const page = Math.ceil(value / carousel.pageSize);
    const idx = value - 1 - (page - 1) * carousel.pageSize;
    dispatch(setPage(page));
    setNewIndex(idx);
    if (isInference) {
      getDataInference();
    } else {
      getData();
    }
  }

  function handleBlur(value) {
    //setImage(index + 1 + (carousel.currentPage - 1) * carousel.pageSize);
    onSubmit(value);
  }

  async function getDataInference() {
    const result = await InferenceAPI.getInferenceDataset(inferenceID, dataset, paramState.page);
    setCarousel({
      currentPage: result.currentPage,
      firstRowOnPage: result.firstRowOnPage,
      lastRowOnPage: result.lastRowOnPage,
      pageCount: result.pageCount,
      pageSize: result.pageSize,
      rowCount: result.rowCount
    });
    setData(result.results);
  }

  async function getData() {
    const result = await DatasetAPI.getAnnotateImages(
      dataset,
      paramState.page,
      paramState?.search,
      {
        q: paramState?.search,
        markers: paramState?.markers,
        orderBy: paramState?.orderby,
        tags: paramState?.tags,
        withoutTags: paramState?.withoutTags
      }
    );
    setData(result.results);
    setCarousel({
      currentPage: result.currentPage,
      firstRowOnPage: result.firstRowOnPage,
      lastRowOnPage: result.lastRowOnPage,
      pageCount: result.pageCount,
      pageSize: result.pageSize,
      rowCount: result.rowCount
    });
    // console.log('result.results', result.results);
  }
  // console.log('data', data);
  // console.log('paramState', paramState);

  return (
    <Container className="w-100 no-margin" style={{ zIndex: 100, float: 'left' }}>
      <div style={{ display: 'flex', width }}>
        <div style={{ width: '100%' }}>{/* <Title>Images</Title> */}</div>
        <div style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
          <ShowLink onClick={() => handleShow()}>{textLink}</ShowLink>
        </div>
      </div>
      <CarouselDiv style={{ zIndex: 100, height, width, display: 'flex' }}>
        <div
          className="scrollbar"
          style={{ overflowX: 'hidden', overflowY: 'hidden', height: `${height - 20}px` }}
        >
          <div style={{ overflow, height: `${height - 20}px` }}>
            {data &&
              data.map((item, index) => {
                return (
                  <div
                    key={`${item.id}`}
                    ref={refs[item.id]}
                    style={{ display: 'inline-flex', width: '95px' }}
                  >
                    {item
                      ? selectedImage && selectedImage.id && selectedImage.id === item.id
                        ? setIndex(index)
                        : null
                      : null}
                    <TooltipItem
                      key={index}
                      index={index}
                      item={item}
                      history={history}
                      dataset={dataset}
                      selected={
                        item
                          ? selectedImage && selectedImage.id && selectedImage.id === item.id
                            ? { boxShadow: `inset 0 0 0 3px ${selectedColor}` }
                            : null
                          : null
                      }
                      handleClose={handleClose}
                      showImg={showImg}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <Col
          className="d-flex mt-3 justify-content-center no-margin"
          style={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            height: `${height}px`,
            minWidth: '160px',
            maxWidth: '160px'
          }}
        >
          <Container>
            <Row
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '160px', maxWidth: '160px' }}
            >
              <Col
                className="d-flex justify-content-left no-margin"
                style={{ minWidth: '40px', maxWidth: '40px' }}
              >
                <ButtonIcon
                  className="float-left"
                  disabled={
                    index + 1 + (carousel.currentPage - 1) * carousel.pageSize > 1 ? '' : 'disabled'
                  }
                  onClick={() => showImage(index - 1)}
                >
                  <Icon
                    name="left"
                    style={{
                      height: 30,
                      width: 30,
                      opacity: `${
                        index + 1 + (carousel.currentPage - 1) * carousel.pageSize > 1 ? '1' : '.5'
                      }`
                    }}
                  />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '80px', maxWidth: '80px' }}
              >
                <InputContainer>
                  <Input
                    type="number"
                    placeholder={index + 1 + (carousel.currentPage - 1) * carousel.pageSize}
                    value={image}
                    onChange={e => onChange(e.currentTarget.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        onSubmit(e.currentTarget.value);
                      }
                    }}
                    onBlur={e => handleBlur(e.currentTarget.value)}
                  />
                </InputContainer>
              </Col>
              <Col
                className="d-flex justify-content-right no-margin"
                style={{ minWidth: '40px', maxWidth: '40px' }}
              >
                <ButtonIcon
                  className="float-right"
                  disabled={
                    index + 1 + (carousel.currentPage - 1) * carousel.pageSize < carousel.rowCount
                      ? ''
                      : 'disabled'
                  }
                  onClick={() => showImage(index + 1)}
                >
                  <Icon
                    name="right"
                    style={{
                      height: 30,
                      width: 30,
                      opacity: `${
                        index + 1 + (carousel.currentPage - 1) * carousel.pageSize <
                        carousel.rowCount
                          ? '1'
                          : '.5'
                      }`
                    }}
                  />
                </ButtonIcon>
              </Col>
            </Row>
            <Row
              className="d-flex mt-1 justify-content-center no-margin"
              style={{ minWidth: '160px', maxWidth: '160px', fontSize: '11px' }}
            >
              Image&nbsp;
              {index + 1 + (carousel.currentPage - 1) * carousel.pageSize}
              &nbsp;of&nbsp;
              {carousel.rowCount}
            </Row>
            {/* <Row
              className="d-flex mt-3 justify-content-center no-margin"
              style={{ minWidth: '120px', maxWidth: '120px' }}
            >
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon
                  className="float-right"
                  disabled={carousel.currentPage > 1 ? '' : 'disabled'}
                  onClick={() => prevPage(carousel.currentPage - 1)}
                >
                  <PrevIcon
                    style={{
                      opacity: `${carousel.currentPage > 1 ? '1' : '.5'}`
                    }}
                  />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon
                  disabled={carousel.currentPage < carousel.pageCount ? '' : 'disabled'}
                  onClick={() => nextPage(carousel.currentPage + 1)}
                >
                  <NextIcon
                    style={{
                      opacity: `${carousel.currentPage < carousel.pageCount ? '1' : '.5'}`
                    }}
                  />
                </ButtonIcon>
              </Col>
            </Row>
            <Row
              className="d-flex mt-1 justify-content-center no-margin"
              style={{ minWidth: '120px', maxWidth: '120px', fontSize: '11px' }}
            >
              Page&nbsp;
              {carousel.currentPage}
              &nbsp;of&nbsp;
              {carousel.pageCount}
              {console.log('carousel', carousel)}
              {console.log('data', data)}
            </Row> */}
          </Container>
        </Col>
      </CarouselDiv>
    </Container>
  );
}

export default Carousel;
