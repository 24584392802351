export const strings = {
  action_tag: 'Tag action',
  accuracy: 'Accuracy',
  add: 'Add',
  add_dataset: 'Add a dataset',
  add_folder: 'Add a folder',
  add_delete: 'Add Tag to Delete',
  add_images: 'Add images',
  add_mask: 'Add mask',
  alert: 'Alert',
  all_tags_of_this: 'All tags of this image will be deleted.',
  alternative: 'Alternative',
  api: 'Api',
  api_code: 'api_code',
  api_key: 'api_key',
  apply_filter: 'Apply filter',
  associates: 'Associates',
  auth_error_1: 'Error logging in. Try again later.',
  auth_error_2: 'Error retrieving user information. Try again later.',
  biggest_confusions: 'Biggest confusions',
  body: 'Body',
  bookmarks: 'Bookmarks',
  bounding_box: 'Bounding box',
  box: 'Box',
  brightness: 'Brightness',
  bulkdelete_alert_title: 'Are you sure?',
  bulkdelete_error: 'There was an error deleting the items, please try again.',
  bulkdelete_info_1: 'Select the items you want to delete.',
  bulkdelete_info_2: 'You need to select at least 1 item.',
  bulkdelete_success: 'Items successfully deleted!',
  bulkdelete_msg_1: 'Do you want to delete',
  bulkdelete_msg_2: 'items? After confirmation, all selected items will be permanently deleted.',
  by_size: 'By Size',
  by_classes: 'By classes',
  cancel: 'Cancel',
  cancel_training: 'Cancel Training',
  checkpoint: 'Checkpoint',
  class: 'Class',
  clear_filter: 'Clear filter',
  close: 'Close',
  close_details: 'Close details',
  confusion_matrix: 'Confusion Matrix',
  collapse_images: 'Collapse images',
  color_pattern: 'Color pattern',
  complete_statistics: 'Complete Statistics',
  confidence: 'Confidence',
  confidence_level: 'Confidence level',
  confirm: 'Confirm',
  confirm_delete: 'Confirm delete',
  confirm_delete_all_tags: 'Confirm delete all tags',
  confirm_delete_img: 'Confirm delete image',
  confirm_delete_tag: 'Confirm delete tag',
  content_question: 'What do you want to do in',
  contrast: 'Contrast',
  convert: 'Convert',
  convert_video_to_image: 'Convert video to images',
  create: 'Create',
  created: 'Created',
  create_at: 'Create At',
  create_bookmark: 'create bookmark',
  create_inference: 'create inference',
  cursor_crosshair: 'Select cursor: Crosshair',
  cursor_default: 'Select cursor: Default',
  daily: 'Daily',
  dataset_already_selected: 'Dataset already selected',
  dataset_deleted: 'Dataset has been deleted',
  datasets: 'Datasets',
  days: 'days',
  delete: 'Delete',
  delete_all_tags: 'Delete all tags',
  delete_tags: 'Delete tags',
  description: 'Description',
  details: 'Details',
  dimension: 'Dimension',
  do_nothing: 'Do Nothing',
  double: 'double',
  download: 'Download',
  earlyStep: 'Early Step',
  elements: 'Elements',
  edit: 'Edit',
  edit_color: 'Edit Color',
  edit_folder: 'Edit Folder',
  edit_mask: 'Edit Mask',
  edit_model: 'Edit Model',
  edit_name: 'Edit Name',
  edit_tag: 'Edit Tag',
  edit_tag_color: 'Edit Tag Color',
  effects: 'Effects',
  email: 'Email',
  enter: 'Enter',
  enter_folder_name: 'Enter folder name',
  enter_initial_name_image: 'Enter the initial name of the images',
  enter_model_name: 'Enter model name',
  enter_new_bookmark: 'Enter the name of the new bookmark',
  enter_new_dataset_name: 'Enter the name of the new dataset',
  enter_new_folder_name: 'Enter the name of the new folder',
  enter_new_members_email: "Enter the new member's email",
  enter_term: 'Enter a term to search',
  enter_total_image: 'Enter number of images',
  error: 'Error',
  errors: 'Errors',
  error_editing: 'Editing is active, please finalize.',
  error_image: 'Error on image',
  error_unexpected: 'Unexpected error.',
  eval: 'Eval',
  evalDatasets: 'Eval Datasets',
  eval_class: 'Eval Class',
  failed: 'Failed',
  fetch_bookmarks: 'fetch bookmarks',
  filter: 'Filter',
  filters: 'Filters',
  finish: 'Finish',
  finished: 'Finished',
  finished_at: 'Finished At',
  fit: 'Fit',
  flip_horizontal: 'Flip horizontal',
  flip_vertical: 'Flip vertical',
  fn: 'FN',
  folders: 'Folders',
  form_data: 'form_data',
  found_boxes: 'Found Boxes',
  height: 'height',
  hide: 'Hide',
  hide_all_names: 'Hide all Tags Name',
  hide_all_tags: 'Hide all Tags',
  hide_tag: 'Hide Tag',
  historic: 'Historic',
  home: 'Home',
  hours: 'hours',
  hue: 'Hue',
  id: 'ID',
  image: 'Image',
  image_binary: 'image.jpg : binary',
  image_contains: 'Image contains',
  image_store: 'Image Store',
  images: 'Images',
  image_changed: 'Imagem alterada com sucesso!',
  images_train_eval: 'Images Train/Eval',
  images_with_inference: 'Images with inference',
  images_without_inference: 'Images without inference',
  infer: 'Inference',
  inferenceDatasets: 'Inference Datasets',
  inference_point: 'Inference Point',
  inference_process: 'Inference Process',
  inference_session: 'Inference Session',
  inference_session_logs: 'Inference Session Logs',
  inference_time: 'Inference Time',
  inferences: 'Inferences',
  infinite_scroll_msg: "You've seen it all around here :)",
  initial_name_image: 'Initial name of images',
  insert: 'Insert',
  instructions: 'Instructions',
  invalid_login: 'Invalid Login',
  invite_new_member: 'Invite New Member',
  invoices: 'Invoices',
  invoice_code: 'Invoice code',
  invoice_bill: 'Billing period',
  invoice_date: 'Invoice Date',
  iou: 'IOU',
  is_corrupted: 'is corrupted',
  items_found: 'items found.',
  no_items_found: 'no items found.',
  no_tags: 'No tags',
  keep_all: 'Keep All',
  last_acess: 'Last acess',
  lastStep: 'Last Step',
  last_training: 'Last training',
  left: 'left',
  less_images: 'Less images',
  less_mask: 'Less mask',
  limits_inferences: 'Limits of inferences',
  link_models_msg_1: 'Instructions for implementing the API for inference in smartvision.',
  link_models_msg_2: 'You can upload up to 20 images in this request',
  link_models_msg_3: 'You will need this result for step 2',
  loading: 'Loading',
  loading_training: 'Loading training process...',
  loading_inference: 'Loading inference process...',
  logn_error: 'expired login',
  machine: 'Machine',
  machine_type: 'Machine Type',
  machine_type_eco: 'Economic',
  machine_type_bal: 'Balanced',
  machine_type_fast: 'Perfomance',
  mandatory: 'Mandatory',
  mark_objects: 'Mark objects in image',
  mask: 'Mask',
  max_priority: 'Max Priority',
  messages: 'Messages',
  message_1: 'Option disabled for preview user.',
  metrics: 'Metrics',
  min_threshold: 'min_threshold',
  minutes: 'minutes',
  model_name: 'Model Name',
  model_error_name: 'The model name entered already exists. Try again with a new name',
  model_id: 'model_id',
  model_key: 'model_key',
  model_msg_choose_tag_type: 'Choose below the type of tagging that the model will use to recognize objects in the images',
  model_msg_choose_natwork: 'Choose below the network that the model will use to recognize the objects in the images',
  model_msg_MaskRCNN_title: 'Mask RCNN Inception v2',
  model_msg_MaskRCNN: 'More robust network, working via cloud.\nBased on instance targeting, returning accurate results.\nRequires more extensive dataset.\nPossibilidade de escolha do número de camadas da rede.',
  model_msg_SSDResnet_title: 'SSDResnet',
  model_msg_SSDResnet: 'Network with good balance between accuracy and inference time.\nPossible use on mobile devices, edge devices and servers.\nIt requires less training time to acquire good accuracy.',
  model_msg_SSD_title: 'SSD Mobilenet v2',
  model_msg_SSD: 'Portable network, running from a cell phone.\nRequires less training time to acquire accuracy.\nLess accurate in object recognition.',
  model_msg_Resnet_title: 'Faster RCNN Resnet 101',
  model_msg_Resnet: 'Robust network, working via cloud.\nIt requires more training time to acquire greater accuracy.\nMore accurate in object recognition.\nPossibility of choosing the number of layers in the network.\nPre-trained with COCO base.',
  model_msg_YOLOv5L_title: 'YOLO version 5 Large',
  model_msg_YOLOv5Lt: 'bla bla bla Large',
  model_msg_YOLOv5M_title: 'YOLO version 5 Medium',
  model_msg_YOLOv5M: 'bla bla bla Medium',
  model_msg_YOLOv5S_title: 'YOLO version 5 Small',
  model_msg_YOLOv5S: 'bla bla bla Small',
  model_msg_YOLOv5N_title: 'YOLO version 5 Nano',
  model_msg_YOLOv5Nt: 'bla bla bla Nano',
  model_msg_1: 'What do you want to accomplish?',
  models: 'Models',
  monthly: 'Monthly',
  more_images: 'More images',
  name: 'Name',
  name_exists2:
    'This name already exists, the images will be inserted in this dataset. If the initial name is the same as the existing ones, the images will be overlaid.',
  name_exists: 'This name already exists, please choose another one.',
  name_of_new_dataset: 'Name of the new dataset',
  name_of_new_dataset_sent: 'Name of the new dataset where the images will be sent',
  name_of_new_folder: 'Name of the new folder',
  next: 'Next',
  network: 'Network',
  network_type: 'Network Type',
  new_dataset: 'New dataset',
  new_folder: 'New folder',
  new_model: 'New model',
  new_tag_name: 'New Tag Name',
  new_training: 'New training',
  no_data: 'No Data',
  no_datasets_selected: 'No datasets selected',
  no_folders_selected: 'No folders selected',
  notifications: 'Notifications',
  older: 'Older',
  old_training: 'Old training',
  open: 'Open',
  open_annotate: 'Open Annotate',
  open_details: 'Open details',
  optional_def: 'Optional (default 0.6)',
  option_disabled: 'Option disabled for preview user.',
  order_by: 'Order By',
  original: 'Original',
  paid_out: 'Paid out',
  paper: 'Paper',
  parameters: 'Parameters',
  password: 'Password',
  pending: 'Pending',
  polygon: 'Polygon',
  precision: 'Precision',
  precisions: 'Precisions',
  preparing: 'Preparing',
  preview: 'Preview',
  previous: 'Previous',
  processing: 'Processing',
  profile: 'profile',
  progress: 'Progress',
  question_one_or_more: 'There is one or more images with the same name. What do you want to do?',
  quit: 'Quit',
  recall: 'Recall',
  recalls: 'Recalls',
  redo: 'Redo',
  remove: 'Remove',
  remove_delete: 'Remove Delete',
  replace: 'Replace',
  result: 'Result',
  result_step_1: 'result of step 1',
  retraining: 'Restart Training',
  right: 'right',
  rotate_180: 'Rotate 180°',
  rotate_left: 'Rotate left 90°',
  rotate_right: 'Rotate right 90°',
  rotation: 'Rotation',
  running: 'Running',
  save: 'Save',
  save_changes: 'Save Changes',
  saving: 'Saving',
  scale: 'scale 1:1',
  scheduled: 'Scheduled',
  search_dataset: 'Search for some dataset',
  search_folder: 'Search for some folder',
  search_folder_image: 'Search for any folder or image',
  search_model: 'Search for some model',
  search_user: 'Search user',
  seconds: 'seconds',
  see_instructions: 'see instructions',
  select: 'Select',
  select_and_next: 'Select and Next',
  select_folder: 'select a folder',
  select_all: 'Select all',
  select_all_folders: 'Select all folders',
  select_bookmark: 'Select a bookmark to add',
  select_datasets: 'Select the datasets',
  select_hotkey: 'Select hotkey between 0 and 9',
  select_inference_point: 'Select an inference point',
  select_one_inference: 'Select at least one inference point and dataset',
  select_one_tag: 'Select at least one tag',
  select_source: 'Select the source',
  selected_video: 'Selected video',
  send: 'Send',
  settings: 'Settings',
  setup: 'Setup',
  show: 'Show',
  show_all_images: 'Show all images',
  show_all_names: 'Show all Tags Name',
  show_all_tags: 'Show all Tags',
  show_details: 'Show Details',
  show_tag: 'Show Tag',
  sidebar: 'Sidebar',
  smartVision_platform: 'SmartVision Platform',
  sort_by: 'Sort by',
  standard: 'Standard',
  started_at: 'Started At',
  statistic: 'Statistic',
  statistics_msg_1: 'metric does not exist',
  status: 'Status',
  step_1: 'Step 1',
  step_2: 'Step 2',
  steps: 'steps',
  steps_number: 'Steps number',
  steps_of: 'steps of',
  string: 'String',
  subscription_control: 'Subscription control',
  tag: 'Tag',
  tag_description: 'tag description',
  tag_folder: 'in folder',
  tag_images: 'in the current image',
  tag_type: 'Tag Type',
  tags: 'Tags',
  threshold: 'Threshold',
  ticket: 'ticket',
  titleRotation: 'Rotate & Flip',
  tools: 'Tools',
  top: 'top',
  total: 'Total',
  total_elements: 'Total elements',
  total_image: 'Total Images',
  total_steps: 'Total Steps',
  trace: 'trace',
  train: 'Train',
  train_already_trained: 'Already trained',
  train_not_trained: 'Not trained',
  train_choose_tag: 'Choose tags to train process',
  train_there_are_no: 'There are no previous trainings.',
  train_load_last: 'Load the latest training',
  train_folders_for_train: 'Folders that will be used for training',
  train_folders_for_eval: 'Folders that will be used for evaluation',
  train_step_1: 'Training | Step 1/3',
  train_step_2: 'Evaluation(Optional) | Step 2/3',
  train_step_3: 'Configuration | Step 3/3',
  training: 'Training',
  trainings: 'Trainings',
  trainingDatasets: 'Training Datasets',
  training_class: 'Training Class',
  training_eval_class: 'Training/Eval Class',
  training_history: 'Training History',
  training_process: 'Training Process',
  training_session: 'Training Session',
  training_session_logs: 'Training Session Logs',
  training_steps: 'Training Steps',
  training_time: 'Training Time',
  training_finish: 'Training finish',
  type: 'Type',
  uncheck_all: 'uncheck all',
  uncheck_folders: 'Uncheck folders',
  undo: 'Undo',
  uploading_images: 'Uploading images',
  uploading_video: 'Uploading video',
  user: 'User',
  user_list: 'User list',
  users: 'Users',
  used_boxes: 'Used Boxes',
  used_boxes_eval: 'Used Boxes Eval',
  used_boxes_training: 'Used Boxes Training',
  version: 'Version',
  wait_process_to_finish: 'Wait for the images output process to finish.',
  warning: 'Warning',
  warnings: 'Warnings',
  width: 'width',
  with_inference: 'with',
  without_inference: 'without',
  younger: 'Younger',
  zoom: 'Zoom'
};
