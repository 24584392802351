import React, { useState, useRef, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import FolderItem from 'components/FolderItem';
import Searchbar from 'components/Searchbar';
import InfiniteScrollV2 from 'components/InfiniteScrollV2';
import Dialog from 'components/Dialog';
import { createFolder } from 'services/Api/Dataset/dataset';
import { getFolders, getAllDatasets } from 'services/Api/Dataset/dataset';
import BulkDeleteSelection from 'components/BulkDeleteSelection';
import { deleteFolder } from 'services/Api/Dataset/dataset';
import { getMarkers } from 'services/Api/Markers';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilter, setSearchTotal } from 'store/ducks/filter';
import { t, strings } from 'language';
import { Label, InputField, LabelAlert } from './styles';

function FolderBrowser(props) {
  const [modal, setModal] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);
  const [markers, setMarkers] = useState([]);
  const scrollRef = useRef();
  const filterState = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const [folders, setFolders] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const alert = (
    <LabelAlert style={{ display: showAlert ? 'block' : 'none' }}>
      {t(strings.name_exists)}
    </LabelAlert>
  );

  async function getAllSets() {
    const resultFolders = await getAllDatasets();
    setFolders(resultFolders.results);
  }

  async function getAllMarkers() {
    const resultMarkers = await getMarkers();
    setMarkers(resultMarkers.results);
    return resultMarkers;
  }

  useEffect(() => {
    getAllMarkers();
    getAllSets();
    dispatch(clearFilter());
  }, []);

  function sameNameTest(name) {
    setShowAlert(false);
    folders.map(item => {
      if (item.name === name) {
        setShowAlert(true);
      }
    })
  }

  function handleNewFolder(e) {
    setModal(true);
    e.preventDefault();
  }
  function handleNewFolderChange(text) {
    sameNameTest(text);
    setNewFolder(text);
  }
  async function createNewFolder() {
    await createFolder({ name: newFolder });
    setModal(false);
    scrollRef.current.resetScroll();
  }

  let newItem = {
    title: t(strings.new_dataset),
    image: 'newFolder',
    id: 'new',
    folders: [],
    newItem: true
  };

  const ScrollItem = props => (
    <BulkDeleteSelection key={props.itemkey} data={props.item}>
      <FolderItem
        key={props.item.id}
        data={props.item}
        markers={markers}
        updateMarkers={getAllMarkers}
        onResetData={() => scrollRef.current.resetScroll()}
        onChangeFilter={setFilter}
      >
        {props.item.name}
      </FolderItem>
    </BulkDeleteSelection>
  );
  
  return (
    <>
      <Searchbar
        value={search}
        onChange={setSearch}
        placeholder={t(strings.search_folder)}
        buttonClick={e => handleNewFolder(e)}
        buttonImage={newItem.image}
        deleteFunction={deleteFolder}
        onResetData={() => scrollRef?.current?.resetScroll()}
        markers={markers}
        onChangeFilter={setFilter}
        filter={filter}
        section="Folder"
      />
      <Container>
        <Row>
          <InfiniteScrollV2
            ref={scrollRef}
            pagedResource={getFolders}
            customParams={{
              query: {
                query: search,
                markers: filterState?.markers,
                orderBy: filterState?.orderby,
                tags: filterState?.tags,
                withoutTags: filterState?.withoutTags
              }
            }}
            size={100}
            ItemComponent={ScrollItem}
          />
        </Row>
      </Container>

      <Dialog
        title={t(strings.new_folder)}
        open={modal}
        close={() => {
          setModal(false);
          setShowAlert(false);
        }}
        actions={[
          {
            text: t(strings.cancel),
            action: () => {
              setModal(false);
              setShowAlert(false);
            }
          },
          {
            text: t(strings.create),
            action: () => {
              if (!showAlert) createNewFolder();
            },
            gold: !showAlert
          }
        ]}
      >
        <Label>{t(strings.name_of_new_folder)}</Label>
        <InputField
          type="text"
          placeholder={t(strings.enter_new_folder_name)}
          onChange={e => handleNewFolderChange(e.target.value)}
        />
        {alert}
      </Dialog>
    </>
  );
}

export default FolderBrowser;
