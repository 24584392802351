import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ModelItem from 'components/ModelItem';
import Searchbar from 'components/Searchbar';
import InfiniteScrollV2 from 'components/InfiniteScrollV2';
import Dialog from 'components/Dialog';
import { checkIfModelExists, createModel, deleteModel, getModels } from 'services/Api/Model/model';
import { toast } from 'react-toastify';
import newModelButton from 'assets/images/newmodelbutton.svg';
import { BrowserType } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchTotal } from 'store/ducks/filter';
import BulkDeleteSelection from 'components/BulkDeleteSelection';
import { getMarkers, getNetworkConfig } from 'services/Api/Markers';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';
import {
  Label,
  Title,
  InputField,
  TypeItem,
  TypeContainer,
  Description,
  NetworkItem,
  NetworkContainer
} from './styles';

function ModelBrowser() {
  const scrollRef = useRef();
  const [modal, setModal] = useState(false);
  const [type, setType] = useState('BoundingBox');
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [network, setNetwork] = useState('SSD');
  const [newModel, setNewModel] = useState('');
  const [step, setStep] = useState(1);
  const [actionButton, setActionButton] = useState(t(strings.next));
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [networkConfig, setNetworkConfig] = useState([]);
  const filterState = useSelector(state => state.filter);

  async function getAllMarkers() {
    const resultMarkers = await getMarkers();
    setMarkers(resultMarkers.results);

    return resultMarkers;
  }
  
  async function getNetworkConfigs() {
    const resultNetwork = await getNetworkConfig();
    setNetworkConfig(resultNetwork);

    return resultNetwork;
  }

  useEffect(() => {
    if (type === 'Polygon') {
      setNetwork('MaskRCNN');
    }
  }, [type]);

  useEffect(() => {
    getAllMarkers();
  }, []);

  useEffect(() => {
    getNetworkConfigs();
  }, []);

  function handleNewModel(text) {
    setNewModel(text);
  }

  function handleOpenNewModelDialog(e) {
    setModal(true);
    e.preventDefault();
  }

  async function setClickActionButton() {
    if (step === 1) {
      if (newModel === '') {
        toast.error(t(strings.enter_model_name));
        return;
      }

      const checkModel = await checkIfModelExists(newModel);
      console.log(checkModel);
      if (checkModel?.results[0]?.name === newModel) {
        toast.error(t(strings.model_error_name));
        return;
      }
      setActionButton(t(strings.create));
      setStep(2);
    }
    if (step === 2) {
      createNewModel();
    }
  }

  function clearForm() {
    setStep(1);
    setActionButton(t(strings.next));
    setNewModel('');
    setType('BoundingBox');
  }

  async function createNewModel() {
    await createModel({ name: newModel, networkType: network, tagType: type });
    setModal(false);
    clearForm();
    scrollRef.current.resetScroll();
  }

  const ScrollItem = props => (
    <BulkDeleteSelection key={props.itemkey} data={props.item}>
      <ModelItem
        onChangeFilter={setFilter}
        key={props.item.id}
        data={props.item}
        markers={markers}
        updateMarkers={getAllMarkers}
        onResetData={() => scrollRef.current.resetScroll()}
        type={BrowserType.MODEL}
      >
        {props.item.name}
      </ModelItem>
    </BulkDeleteSelection>
  );

  return (
    <>
      <Searchbar
        value={search}
        onChange={setSearch}
        onMakeSearch={() => scrollRef.current.resetScroll()}
        placeholder={t(strings.search_model)}
        buttonClick={e => handleOpenNewModelDialog(e)}
        onResetData={() => scrollRef.current.resetScroll()}
        onChangeFilter={setFilter}
        filter={filter}
        buttonImage="newmodelbutton"
        markers={markers}
        deleteFunction={deleteModel}
        section="Model"
      />
      <Container>
        <Row>
          <InfiniteScrollV2
            ref={scrollRef}
            pagedResource={getModels}
            customParams={{
              query: {
                query: search,
                markers: filterState?.markers,
                orderBy: filterState?.orderby,
                tags: filterState?.tags,
                withoutTags: filterState?.withoutTags,
                tagType: filterState?.tagType,
                networkType: filterState?.networkType,
                trainingStatus: filterState?.trainingStatus
              }
            }}
            size={100}
            ItemComponent={ScrollItem}
          />
        </Row>
      </Container>
      <Dialog
        title={t(strings.new_model)}
        open={modal}
        width={800}
        actions={[
          {
            text: t(strings.cancel),
            action: () => {
              setModal(false);
              clearForm();
            }
          },
          { text: actionButton, action: () => setClickActionButton() }
        ]}
      >
        {step === 1 ? (
          <div className="container_grande">
            <Label>{t(strings.model_name)}</Label>
            <InputField
              type="text"
              placeholder={t(strings.enter_model_name)}
              onChange={e => handleNewModel(e.target.value)}
            />
            <Description>
              
            </Description>

            <TypeContainer>
              <TypeItem onClick={() => setType('BoundingBox')} selected={type === 'BoundingBox'}>
                <img src={require('./assets/images/box.png')} alt="" />
                <p>
                  <img src={require('./assets/images/boxIcon.png')} alt="" />
                  {t(strings.box)}
                </p>
              </TypeItem>

              <TypeItem onClick={() => setType('Class')} selected={type === 'Class'}>
                <img src={require('./assets/images/classe.png')} alt="" />
                <p>
                  <img src={require('./assets/images/classeIcon.png')} alt="" />
                  {t(strings.class)}
                </p>
              </TypeItem>

              <TypeItem onClick={() => setType('Polygon')} selected={type === 'Polygon'}>
                <img src={require('./assets/images/poligon.png')} alt="" />
                <p>
                  <img src={require('./assets/images/poligonIcon.png')} alt="" />
                  {t(strings.polygon)}
                </p>
              </TypeItem>
            </TypeContainer>
          </div>
        ) : (
          <NetworkContainer>
            <Description>{t(strings.model_msg_choose_natwork)}</Description>
             {networkConfig && (networkConfig.map((net, index) => {
              return (
                <NetworkItem
                  onClick={() => setNetwork(net.networkType)}
                  selected={network === net.networkType}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  <Title>{t(strings[`model_msg_${net.networkType}_title`])}</Title>
                  <p>{t(strings[`model_msg_${net.networkType}`])}</p>
                </NetworkItem>
              );
            }))} 

            {/* {type === 'Polygon' ? (
              <NetworkItem onClick={() => setNetwork('MaskRCNN')} selected={network === 'MaskRCNN'}>
                <h4>{t(strings.model_msg_RCNNInception_title)}</h4>
                <p>{t(strings.model_msg_RCNNInception_1)}</p>
                <p>{t(strings.model_msg_RCNNInception_2)}</p>
                <p>{t(strings.model_msg_RCNNInception_3)}</p>
                <p>{t(strings.model_msg_RCNNInception_4)}</p>
              </NetworkItem>
            ) : (
              <>
                <NetworkItem
                  onClick={() => setNetwork('SSDResnet')}
                  selected={network === 'SSDResnet'}
                >
                  <h4>{t(strings.model_msg_SSDResnet_title)}</h4>
                  <p>{t(strings.model_msg_SSDResnet_1)}</p>
                  <p>{t(strings.model_msg_SSDResnet_2)}</p>
                  <p>{t(strings.model_msg_SSDResnet_3)}</p>
                </NetworkItem>
                <NetworkItem onClick={() => setNetwork('SSD')} selected={network === 'SSD'}>
                  <h4>{t(strings.model_msg_SSDMobilenet_title)}</h4>
                  <p>{t(strings.model_msg_SSDMobilenet_1)}</p>
                  <p>{t(strings.model_msg_SSDMobilenet_2)}</p>
                  <p>{t(strings.model_msg_SSDMobilenet_3)}</p>
                </NetworkItem>
                <NetworkItem onClick={() => setNetwork('Resnet')} selected={network === 'Resnet'}>
                  <h4>{t(strings.model_msg_RCNN101_title)}</h4>
                  <p>{t(strings.model_msg_RCNN101_1)}</p>
                  <p>{t(strings.model_msg_RCNN101_2)}</p>
                  <p>{t(strings.model_msg_RCNN101_3)}</p>
                  <p>{t(strings.model_msg_RCNN101_4)}</p>
                  <p>{t(strings.model_msg_RCNN101_5)}</p>
                </NetworkItem>
              </>
            )} */}

          </NetworkContainer>
        )}
      </Dialog>
    </>
  );
}

export default ModelBrowser;
