import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BrowserType, TypeItem } from 'utils/constants';
import { ModelAPI } from 'services/Api/Model';
import { Row, Col, Container as ContainerStrap } from 'reactstrap';
import Moment from 'react-moment';
import {
  Container,
  ContainerTop,
  Name,
  Image,
  Tags,
  ModelItemDetail,
  IconContainer,
  EditButton,
  Markers,
  StarContainer
} from './styles';

import { useDispatch, useSelector } from 'react-redux';
import { loading } from 'store/ducks/general';
import Dialog from 'components/Dialog';
import { Label, InputField } from 'components/FolderBrowser/styles';

import Favorite from 'components/Favorite';
import Marker from 'components/Marker';
import { setMarkers } from 'store/ducks/filter';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';

function ModelItem(props) {
  const { data, children, type, markers, updateMarkers, onResetData, onChangeFilter } = props;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [modelName, setModelName] = useState(children);

  const [showDialog, setShowDialog] = useState(false);
  const [editModelName, setEditModelName] = useState(children);
  const [editModelId, setEditModelId] = useState('');
  const [markerList, setMarkerList] = useState([]);

  function openEditModel(event, name, id) {
    event.preventDefault();
    setShowDialog(true);
    setModelName(name);
    setEditModelId(id);
  }

  function closeModal() {
    setShowDialog(false);
  }

  async function editName() {
    dispatch(loading(true));
    await ModelAPI.editModel(editModelId, {
      name: editModelName,
      networkType: data.networkType,
      tagType: data.tagType
    });
    closeModal();
    setModelName(editModelName);
    dispatch(loading(false));
  }

  const onMarkerClick = item => {
    //item.preventDefault();
    // setMarkerList([item.id]);
    // onChangeFilter([item.id]);
    // onResetData();
    dispatch(setMarkers([{ label: item.name, value: item.id }]));
  };

  function getLink(data) {
    switch (type) {
      case BrowserType.MODEL:
        if (data.folders > 0) {
          return `/models/${data.id}`;
        }
        if (data.root) {
          return `/models`;
        }
        return `/model/${data.id}`;
      case BrowserType.DATASET:
        if (data.folders > 0) {
          return `/datasets/${data.id}`;
        }
        if (data.root) {
          return `/datasets`;
        }
        return `/dataset/${data.id}`;
      default:
        break;
    }
  }
  
  return (
    <Container className="">
      <ContainerTop>
        <Markers>
          <div className="d-flex flex-row w-100 justify-content-end">
            {data.markers.slice(0, 2).map(item => (
              <a onClick={() => onMarkerClick(item)}>
                <p title={item.name}>{item.name}</p>
              </a>
            ))}
            <StarContainer>
              <Favorite data={data} type={TypeItem.MODEL} />
            </StarContainer>
          </div>
        </Markers>
        <Tags>
          <div className="d-flex flex-row w-100 justify-content-end">
            <span>{data.tagType}</span>
            <span>{data.networkType}</span>
          </div>
        </Tags>
        <Link to={getLink(data)} style={{ textAlign: 'left' }}>
          <IconContainer bg={require('assets/images/modelIconIdle.svg')}>
            {data?.lastTrainingSession?.status === 'Finished' && (
              <Icon
                name="modelIcon"
                width="25"
                height="25"
                style={{ marginLeft: 7, marginTop: 7 }}
              />
            )}
            {data?.lastTrainingSession?.status === 'Error' && (
              <Icon
                name="modelIconError"
                width="25"
                height="25"
                style={{ marginLeft: 7, marginTop: 7 }}
              />
            )}
            {data?.lastTrainingSession?.status === 'Processing' && (
              <Icon
                name="modelIconSuccess"
                width="25"
                height="25"
                style={{ marginLeft: 7, marginTop: 7 }}
              />
            )}
          </IconContainer>
          <ModelItemDetail>
            <strong>{t(strings.steps)}</strong>:{' '}
            {data?.lastTrainingSession?.steps ? data?.lastTrainingSession?.steps : '-'}
          </ModelItemDetail>

          <ModelItemDetail>
            <strong>{t(strings.images_train_eval)}</strong>:{' '}
            {data?.lastTrainingSession
              ? `${data?.lastTrainingSession?.usedImagesTraining} / ${data?.lastTrainingSession?.usedImagesEval}`
              : '-'}
          </ModelItemDetail>

          <ModelItemDetail>
            <strong>{t(strings.last_training)}</strong>:{' '}
            {data?.lastTrainingSession?.finishedAt ? (
              <Moment local utc format="DD/MM/YYYY">
                {data?.lastTrainingSession?.finishedAt}
              </Moment>
            ) : (
              '-'
            )}
          </ModelItemDetail>
        </Link>
      </ContainerTop>
      <Link to={getLink(data)}>
        <Name>
          {!viewer && (
            <EditButton
              className="editButton"
              //mouseOver={mouseOver || showDialog}
              onClick={event => openEditModel(event, children, data.id)}
            ></EditButton>
          )}
          <h3>{modelName}</h3>
          {!viewer && (
            <Marker
              markers={markers}
              onResetData={onResetData}
              updateMarkers={updateMarkers}
              data={data}
              type={TypeItem.MODEL}
            />
          )}
        </Name>
      </Link>
      <Dialog
        title={t(strings.edit_name)}
        open={showDialog}
        close={closeModal}
        actions={[
          { text: t(strings.cancel), action: closeModal },
          { text: t(strings.save), action: () => editName() }
        ]}
      >
        <Label>{t(strings.edit_model)}</Label>
        <InputField
          defaultValue={editModelName}
          type="text"
          placeholder={t(strings.enter_model_name)}
          onChange={e => setEditModelName(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && editName()}
        />
      </Dialog>
    </Container>
  );
}

export default ModelItem;
