import { getToken } from 'store/ducks/auth';
import { toast } from 'react-toastify';

function getHeader() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + getToken()
  };
}

const BASE_URL = process.env.REACT_APP_API;

export function resolveFilter(filter) {
  // console.log('dentro filter', filter);
  let query = '';
  if (filter?.query) query = `&q=${filter?.query}`;
  if (filter?.q) query = `&q=${filter?.q}`;

  if (filter?.markers?.length > 0) {
    query += `${filter?.markers.map(item => '&markers=' + item.value)}`.replace(/\,/g, '');
  }

  if (filter?.orderBy?.length > 0) {
    query += `&orderBy=${filter.orderBy}`;
  }
  if (filter?.tagType?.length > 0) {
    query += `${filter?.tagType.map(item => '&TagType=' + item.value)}`.replace(/\,/g, '');
  }
  if (filter?.networkType?.length > 0) {
    query += `${filter?.networkType.map(item => '&NetworkType=' + item.value)}`.replace(/\,/g, '');
  }
  if (filter?.trainingStatus?.length > 0) {
    query += `${filter?.trainingStatus.map(item => '&TrainingStatus=' + item.value)}`.replace(
      /\,/g,
      ''
    );
  }

  if (filter?.page) query += `&page=${filter?.page}`;

  if (filter?.withoutTags) {
    query += `&withoutTags=${filter?.withoutTags}`;
  } else {
    if (filter?.tags?.length > 0) {
      // console.log('resolver', filter.tags);
      query += `${filter?.tags.map(item => '&tags=' + item.value)}`.replace(/\,/g, '');
    }
  }


  return query;
}

export function resolveParameters(param) {
  // console.log('resolveParameters', param)
  let query = '';
  if (param?.page) query = `&page=${param?.page}`;
  if (param?.query) query += `&q=${param?.query}`;
  if (param?.markers?.length > 0) {
    query += `${param?.markers.map(item => `&markers=${item}`)}`.replace(/\,/g, '');
  }
  if (param?.orderBy?.length > 0) {
    query += `&orderBy=${param.orderBy}`;
  }
  if (param?.withoutTags) {
    query += `&withoutTags=${param?.withoutTags}`;
  } else if (param?.tags?.length > 0) {
    query += `${param?.tags.map(item => `&tags=${item}`)}`.replace(/\,/g, '');
  }
  return query;
}

export const apiGet = async (endpoint, handle401 = true) => {
  try {
    let response = await fetch(`${BASE_URL}${endpoint}`, {
      headers: getHeader(),
      method: 'GET'
    });
    let data;
    try {
      data = await response.json();
    } catch {
      data = null;
    }

    if (response.ok) {
      return data;
    }
    switch (response.status) {
      case 401:
        if (handle401) {
          window.location.href = '/login?expired=true';
        } else {
          throw new Error(`response.statusText ${BASE_URL}${endpoint}`);
        }
        break;
      case 400:
        toast.error(data.title);
        break;
      default:
        throw new Error('Erro inesperado.');
    }
  } catch (error) {
    console.log(`Erro api GET ${BASE_URL}${endpoint}`, error, getHeader());
    throw new Error(error);
  }
};

export const apiPost = async (endpoint, body, handle401 = true) => {
  try {
    let response = await fetch(`${BASE_URL}${endpoint}`, {
      headers: getHeader(),
      method: 'POST',
      body: JSON.stringify(body)
    });

    let data;
    try {
      data = await response.json();
    } catch {
      data = null;
    }

    if (response.ok) {
      return data;
    }

    switch (response.status) {
      case 401:
        if (handle401) {
          window.location.href = '/login?expired=true';
        } else {
          throw new Error(response.statusText);
        }
        break;
      case 400:
        toast.error(data.title);
        break;
      default:
        throw new Error('Erro inesperado.');
    }
  } catch (error) {
    console.log(`Erro api POST ${BASE_URL}${endpoint}`, error, getHeader());
    throw new Error(error);
  }
};

export const apiPut = async (endpoint, body, handle401 = true) => {
  // console.log('apiPut.endpoint', endpoint);
  // console.log('apiPut.body', body);
  try {
    let response = await fetch(`${BASE_URL}${endpoint}`, {
      headers: getHeader(),
      method: 'PUT',
      body: JSON.stringify(body)
    });

    let data;
    try {
      data = await response.json();
    } catch {
      data = null;
    }

    if (response.ok) {
      return data;
    }

    switch (response.status) {
      case 401:
        if (handle401) {
          window.location.href = '/login?expired=true';
        } else {
          throw new Error(response.statusText);
        }
        break;
      case 400:
        toast.error(data.title);
        break;
      default:
        throw new Error('Erro inesperado.');
    }
  } catch (error) {
    console.log(`Erro api PUT ${BASE_URL}${endpoint}`, error, getHeader());
    throw new Error(error);
  }
};

export const apiDelete = async (endpoint, body, handle401 = true) => {
  try {
    let response = await fetch(`${BASE_URL}${endpoint}`, {
      headers: getHeader(),
      method: 'DELETE',
      body: JSON.stringify(body)
    });

    if (response.ok) {
      return;
    }

    switch (response.status) {
      case 401:
        if (handle401) {
          window.location.href = '/login?expired=true';
        } else {
          throw new Error(response.statusText);
        }
        break;
      case 400:
        const data = await response.json();
        toast.error(data.title);
        break;

      default:
        throw new Error('Erro inesperado.');
    }
  } catch (error) {
    console.log(`Erro api DELETE ${BASE_URL}${endpoint}`, error, getHeader());
    throw new Error(error);
  }
};
