import React, { useState, useEffect } from 'react';
//import MultSelectSearchable from '../../MultSelectSearchable';
import { Collapse, Row, Col, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
//import { getTags } from 'services/Api/Tag/tag';
import { setTags, clearFilter, setNetworkType } from 'store/ducks/filter';
//import { getMarkersByText } from 'services/Api/Markers';
//import MultiSelectSearchab from '../../MultSelectSearchable';
import MultiSelect from 'components/MultSelect';
import { t, strings } from 'language';
import { Container } from './styles';

const NetworkTypeFilter = () => {
  const filterState = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const options = [
    { value: 'Resnet', label: 'Resnet' },
    { value: 'SSD', label: 'SSD' },
    { value: 'MaskRCNN', label: 'MaskRCNN' },
    { value: 'SSDResnet', label: 'SSDResnet' }
  ];
  const handleSelect = e => {
    //setSelectedFilters(e);
    console.log(e);

    dispatch(setNetworkType(e));
  };
  // useEffect(() => {
  //   console.log('redux', filterState.markers);
  //   if (filterState.markers) {
  //     setFilterSelection({
  //       markers: filterState.markers
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (selectedFilters) {
  //     setFilterSelection({
  //       ...filterSelection,
  //       markers: selectedFilters.map(i => {
  //         return {
  //           label: i.label,
  //           value: i.value
  //         };
  //       })
  //     });
  //   }
  // }, [selectedFilters]);

  return (
    <Container>
      <br />
      <Label>{t(strings.network_type)}</Label>

      <MultiSelect
        placeholder={t(strings.select)}
        defaultValue={filterState.networkType}
        selected={filterState.networkType}
        onChange={handleSelect}
        options={options}
      />
    </Container>
  );
};

export default NetworkTypeFilter;
