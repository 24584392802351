import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { loading } from 'store/ducks/general';
import { Row, Col, NavItem, Nav, TabPane, TabContent } from 'reactstrap';
import { getIdFromUrl } from 'utils/helpers';
import Switch from 'components/Switch/Switch';
import Slider from 'components/Slider/Slider';
import TitleBreadcrumb from 'components/Breadcrumb';
import {
  getMetricsMatrix,
  getMetricsPrecisionRecall,
  getAvaiableMetrics,
  getMetrics,
  getBestMetrics
} from 'services/Api/Metrics';
import MultiSelect from 'components/MultSelect';
import Select from 'components/Select';
import { ThemeContext } from 'styled-components';
import { t, strings } from 'language';
import {
  ChartContainer,
  GaugeContainer,
  ChartTitle,
  SelectMetric,
  TabLink,
  CopyButton,
  SwitchOptions,
  SwitchContainer,
  TabsContent,
  ChartsContainer,
  ControllerContainer
} from './styles';
import PointChart from './components/PointChart/index';
import MatrixBySize from './components/MatrixBySize';
import Gauge from './components/gauge';
import ConfusionMatrix from './components/confusionMatrix';
import MatrixFooter from './components/MatrixFooter/index';

export default function TrainingStatistics({ history }) {
  const theme = useContext(ThemeContext);
  const [modelId, setModelId] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedIOU, setSelectedIOU] = useState(null);
  const [selectedConfidence, setSelectedConfidences] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const [matrixData, setMatrixData] = useState(null);
  const [selectedMatrix, setSelectedMatrix] = useState(null);
  const [selectedMatrixBySize, setSelectedMatrixBySize] = useState(null);
  const [availableFilters, setAvailableFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [precisionRecallChartData, setPrecisionRecallChartData] = useState(null);
  const [metricChartData, setMetricChartData] = useState(null);
  const [totalPrecision, setTotalPrecision] = useState(0);
  const [totalRecall, setTotalRecall] = useState(0);
  const [totalAccuracy, setTotalAccuracy] = useState(0);
  const [accControl, setAccControl] = useState(false);
  const [preControl, setPreControl] = useState(false);
  const [recControl, setRecControl] = useState(false);

  const [activeTab, setActiveTab] = useState('1');
  const [advancedGraphicsActiveTab, setAdvancedGraphicsActiveTab] = useState('1');
  const [trainingEvalSwitch, setTrainingEvalSwitch] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const id = getIdFromUrl(history.location.pathname, 2);
    setModelId(id);
  }, []);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleAdvanced = tab => {
    if (advancedGraphicsActiveTab !== tab) setAdvancedGraphicsActiveTab(tab);
  };

  const loadData = async filter => {
    dispatch(loading(true));
    const step = await loadMatrixData(filter);
    await loadChartData({ ...filter, step });
    dispatch(loading(false));
  };

  const loadMatrixData = filter => {
    return new Promise((resolve, reject) => {
      const actualStep = selectedStep;

      getMetricsMatrix(
        modelId,
        filter?.iou ?? selectedIOU,
        filter?.confidence ?? selectedConfidence,
        filter?.step ?? selectedStep
      )
        .then(result => {
          let step = filter?.step ?? selectedStep;
          if (!selectedStep) {
            step = result.steps.reduce((a, b) => {
              return Math.max(a, b);
            });
            setSelectedStep(step);
          }
          setSelectedIOU(result.iou);
          setSelectedConfidences(result.confidence);
          setMatrixData(result);
          setAvailableFilters(
            [
              ...new Set(result.trainMatrix.map(i => i.x).filter(i => i !== 'FN' && i !== 'FP'))
            ].map(i => ({ label: i, value: i }))
          );
          setSelectedFilters(
            [
              ...new Set(result.trainMatrix.map(i => i.x).filter(i => i !== 'FN' && i !== 'FP'))
            ].map(i => ({ label: i, value: i }))
          );
          resolve(step);
        })
        .catch(error => {
          // console.log(error);
          alert(t(strings.statistics_msg_1));
          setSelectedStep(actualStep);
          reject();
        });
    });
  };

  useEffect(() => {
    if (!modelId) return;
    loadData();
  }, [modelId]);

  const selectMatrix = () => {
    if (!matrixData || !selectedFilters) return;
    let matrix = trainingEvalSwitch ? matrixData?.evalMatrix : matrixData?.trainMatrix;
    let matrixBySize = trainingEvalSwitch
      ? matrixData?.evalMatrixBySize
      : matrixData?.trainMatrixBySize;
    const filters = selectedFilters.map(i => i.value);
    matrix = matrix.filter(m => {
      if (m.x === 'FP' || m.x === 'FN') {
        return filters.includes(m.y);
      }
      if (m.y === 'FP' || m.y === 'FN') {
        return filters.includes(m.x);
      }
      return filters.includes(m.x) && filters.includes(m.y);
    });
    setSelectedMatrix(matrix);
    setSelectedMatrixBySize(matrixBySize);
  };

  useEffect(() => {
    selectMatrix();
  }, [trainingEvalSwitch, matrixData, selectedFilters]);

  const loadChartData = filter => {
    return new Promise((resolve, reject) => {
      getMetricsPrecisionRecall(
        modelId,
        filter?.iou ?? selectedIOU ?? 0.5,
        filter?.confidence ?? selectedConfidence ?? 0.5,
        filter?.trainingEval ?? trainingEvalSwitch
      )
        .then(resultPrecisionRecall => {
          setPrecisionRecallChartData(resultPrecisionRecall);
          const selectedStepData = resultPrecisionRecall.data.filter(
            i => i.Step === filter?.step ?? selectedStep
          )[0];
          setTotalAccuracy(selectedStepData?.Accuracy ?? 0);
          setTotalPrecision(selectedStepData?.Precision ?? 0);
          setTotalRecall(selectedStepData?.Recall ?? 0);
          resolve();
        })
        .catch(error => {
          // console.log(error);
          reject();
        });
    });
  };

  // useEffect(() => {
  //   if (!modelId) return;
  //   loadChartData();
  // }, [modelId, trainingEvalSwitch]);

  const loadAvailableMetrics = async () => {
    const result = await getAvaiableMetrics(modelId);
    setMetrics(result);
    setSelectedMetric(result[0]);
  };

  useEffect(() => {
    if (!modelId) return;
    loadAvailableMetrics();
  }, [modelId]);

  const loadMetric = async () => {
    const result = await getMetrics(modelId, selectedMetric);
    setMetricChartData(result);
  };

  useEffect(() => {
    if (!modelId || !selectedMetric) return;
    loadMetric();
  }, [modelId, selectedMetric]);

  if (!selectedMatrix) return <p>{t(strings.loading)}</p>;

  function controlSelectedMax(type) {
    setAccControl(type === 'Accuracy');
    setPreControl(type === 'Precision');
    setRecControl(type === 'Recall');
  }

  async function setMax(type) {
    const best = await getBestMetrics(modelId, type, trainingEvalSwitch);
    controlSelectedMax(type);
    setSelectedStep(best.step);
    setSelectedIOU(best.iou);
    setSelectedConfidences(best.confidence);
    loadData({ step: best.step, iou: best.iou, confidence: best.confidence });
  }

  return (
    <div style={{ paddingLeft: '3em', paddingRight: '3em' }}>
      <TitleBreadcrumb
        position={4}
        name={`Training ${modelId}`}
        url={`/model/${modelId}/trainingstatistics`}
      />
      <Row>
        <Col md="12" className="mb-4">
          <ControllerContainer>
            <Col md="2" xs="12">
              <div className="d-flex align-items-center pl-3 pr-3 mb-3">
                <span>{`${t(strings.iou)}: `}</span>
                <Slider
                  style={{ marginTop: '0px', marginBottom: '0px' }}
                  label=""
                  labels={{
                    0.5: '0.5',
                    0.75: '0.75',
                    0.95: '0.95'
                  }}
                  min={0.5}
                  max={0.95}
                  step={0.25}
                  value={selectedIOU}
                  onChange={e => {
                    controlSelectedMax();
                    setSelectedIOU(e === 0.9 ? 0.95 : e);
                    loadData({ iou: e === 0.9 ? 0.95 : e });
                  }}
                />
              </div>
            </Col>
            <Col md="3" xs="12">
              <div className="d-flex align-items-center pl-3 pr-3  mb-3">
                <span>{`${t(strings.confidence)}: `}</span>
                <Slider
                  style={{ marginTop: '0px', marginBottom: '0px' }}
                  label=""
                  labels={{
                    0.5: '0.5',
                    0.6: '0.6',
                    0.7: '0.7',
                    0.8: '0.8',
                    0.9: '0.9'
                  }}
                  min={0.5}
                  max={0.9}
                  step={0.1}
                  value={selectedConfidence}
                  onChange={e => {
                    controlSelectedMax();
                    setSelectedConfidences(e);
                    loadData({ confidence: e });
                  }}
                />
              </div>
            </Col>
            <Col md="3" xs="12">
              <div className="d-flex align-items-center pl-3 pr-3 ">
                <span>{`${t(strings.steps)}: `}</span>
                <Select
                  className="w-100"
                  selected={selectedStep}
                  options={matrixData?.steps ?? []}
                  onChange={value => {
                    controlSelectedMax();
                    setSelectedStep(value);
                    loadData({ step: value });
                  }}
                />
              </div>
            </Col>
            <Col md="1" xs="6">
              <div className="d-flex justify-content-center ">
                {/* <CopyButton href="#">Copiar</CopyButton> */}
              </div>
            </Col>
            <Col md="3" xs="6">
              <div className="d-flex justify-content-center">
                <SwitchContainer>
                  <SwitchOptions>{t(strings.training)}</SwitchOptions>
                  <Switch
                    ison={trainingEvalSwitch}
                    name="train"
                    //onColor="${props => props.theme.default}"
                    handleToggle={() => {
                      controlSelectedMax();
                      setTrainingEvalSwitch(!trainingEvalSwitch);
                      loadData({ trainingEval: !trainingEvalSwitch });
                    }}
                  />
                  <SwitchOptions>{t(strings.eval)}</SwitchOptions>
                </SwitchContainer>
              </div>
            </Col>
          </ControllerContainer>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="5">
          <Row className="mb-3">
            <GaugeContainer>
              <Gauge
                title={t(strings.accuracy)}
                value={totalAccuracy / 100}
                maxSelect={accControl}
                buttonClick={() => {
                  setMax('Accuracy');
                }}
              />
              <Gauge
                title={t(strings.precision)}
                value={totalPrecision / 100}
                maxSelect={preControl}
                buttonClick={() => {
                  setMax('Precision');
                }}
              />
              <Gauge
                title={t(strings.recall)}
                value={totalRecall / 100}
                maxSelect={recControl}
                buttonClick={() => {
                  setMax('Recall');
                }}
              />
            </GaugeContainer>
          </Row>
          <Row className="mb-3">
            <ChartContainer>
              <ChartTitle>
                {`${t(strings.precision)} & ${t(strings.recall)} / ${t(strings.steps)} `}
              </ChartTitle>
              <PointChart chartData={precisionRecallChartData} xKey="Step" />
            </ChartContainer>
          </Row>
          <Row className="mb-3">
            <Nav tabs style={{ margin: 0, borderBottom: 'none', width: 400 }}>
              <NavItem
                style={{ width: '50%', textAlign: 'center', borderBottom: '1px solid #fff' }}
              >
                <TabLink
                  active={advancedGraphicsActiveTab === '1'}
                  onClick={() => {
                    toggleAdvanced('1');
                  }}
                >
                  {t(strings.complete_statistics)}
                </TabLink>
              </NavItem>
              <NavItem
                style={{ width: '50%', textAlign: 'center', borderBottom: '1px solid #fff' }}
              >
                <TabLink
                  active={advancedGraphicsActiveTab === '2'}
                  onClick={() => {
                    toggleAdvanced('2');
                  }}
                >
                  {t(strings.by_size)}
                </TabLink>
              </NavItem>
            </Nav>
            <ChartsContainer>
              <TabsContent className="tabsContent">
                <TabContent
                  className="pt-3"
                  style={{ minHeight: '460px' }}
                  activeTab={advancedGraphicsActiveTab}
                >
                  <TabPane tabId="1" style={{ textAlign: 'center' }}>
                    {metrics.length > 0 && (
                      <>
                        <div className="d-flex  align-items-center p-3">
                          <span>{`${t(strings.metrics)}: `}</span>
                          <SelectMetric
                            value={selectedMetric}
                            onChange={e => setSelectedMetric(e.target.value)}
                          >
                            {metrics?.map(i => (
                              <option key={i}>{i}</option>
                            ))}
                          </SelectMetric>
                        </div>
                        <ChartContainer Completa>
                          <PointChart chartData={metricChartData} xKey="step" />
                        </ChartContainer>
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {selectedMatrixBySize ? (
                      <ChartContainer>
                        <MatrixBySize data={selectedMatrixBySize} />
                      </ChartContainer>
                    ) : (
                      <div className="d-flex">
                        <span>{t(strings.no_data)}</span>
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </TabsContent>
            </ChartsContainer>
          </Row>
        </Col>
        <Col sm="12" md="7">
          <Row>
            <Col xs="12">
              <div className="d-flex flex-row align-items-end">
                <Nav tabs style={{ borderBottom: 'none', width: '400px' }}>
                  <NavItem
                    style={{ width: '200px', textAlign: 'center', borderBottom: '1px solid #fff' }}
                  >
                    <TabLink
                      active={activeTab === '1'}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      {t(strings.by_classes)}
                    </TabLink>
                  </NavItem>

                  <NavItem
                    style={{ width: '200px', textAlign: 'center', borderBottom: '1px solid #fff' }}
                  >
                    <TabLink
                      active={activeTab === '2'}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      {t(strings.confusion_matrix)}
                    </TabLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div
                style={{
                  backgroundColor: theme.secondary,
                  borderRadius: '10px',
                  borderTopLeftRadius: '0px',
                  minHeight: '992px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <TabsContent className="tabsContent">
                  {selectedFilters && (
                    <div className="d-flex align-items-center p-3">
                      <span>{`${t(strings.filters)}: `}</span>
                      <MultiSelect
                        className="w-100"
                        selected={selectedFilters}
                        options={availableFilters}
                        onChange={setSelectedFilters}
                      />
                    </div>
                  )}
                  <TabContent className="pt-3" activeTab={activeTab}>
                    <TabPane tabId="1">
                      <ConfusionMatrix byClass matrix={selectedMatrix} />
                    </TabPane>
                    <TabPane tabId="2" style={{ textAlign: 'center' }}>
                      <ConfusionMatrix matrix={selectedMatrix} />
                    </TabPane>
                  </TabContent>
                </TabsContent>
                <MatrixFooter matrix={selectedMatrix} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
